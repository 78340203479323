@use '../lib';

.heroBanner{
  &__banner {
    background-image: url('#{lib.$assetPath}/images/wave_purple.jpg');
    background-size: cover;
    background-position: center top;
    min-height: 25rem;
    width: 100%;
    position: relative;
  
    @include lib.mq($from: 'tablet') {
      // background-image: url('#{lib.$assetPath}/images/wave_purple.jpg');
      // height: 500px;
    }

    @include lib.mq($from: 'monitor') {
      height: 31.25rem;
    }
  
    @include lib.mq($from: 'large') {
      background-position: center -10rem;
    }

    &--grey{
      background-image: url('#{lib.$assetPath}/images/wave_grey.jpg');
    }

    &::before{
      @include lib.mq($from: 'monitor') {
        @include lib.bodyMetaMini(false, rgba(lib.color('body'), 0.5));
        content: attr(data-label);
        position: absolute;
        top: 50%;
        left: 3rem;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(270deg);
        text-align: center;
        width: 30%;
      }
    }

  }

  &__header{
    max-width: 1110px;
    padding: 1.25rem 1.25rem 0;
    height: 100%;
    margin: 0 auto;
    
    @include lib.mq($from: "tablet") {      
      padding: 2.5rem 2.5rem 0;
    }

    @include lib.mq($from: 'laptop') {
      padding: 2.5rem 3.55rem 0;
    }

    @include lib.mq($from: "desktop") {
      padding: 2.75rem 0 0;
    }

    &--flex{
      display: flex;
      flex-direction: column;
    }
}

&__headerText{
    padding: 2.5rem 0;
    
    @include lib.mq($from: 'laptop') {
      display: flex;
      height: 100%;
      align-items: center;
      position: relative;
    }    

    // &::before{
    //   @include lib.mq($from: 'monitor') {
    //     @include lib.bodyMetaMini(false, rgba(lib.color('body'), 0.5));
    //     content: attr(data-label);
    //     position: absolute;
    //     top: 50%;
    //     left: -22rem;
    //     transform-origin: center;
    //     transform: translate(6rem, -50%) rotate(270deg);
    //     text-align: center;
    //     width: 30%;
    //   }
    // }
}


// &__headerTextLabel{
//   @include lib.bodyMetaMini(false, rgba(lib.color('body'), 0.5));
//   position: absolute;
//   top: 50%;
//   left: -22rem;
//   transform-origin: center;
//   transform: translate(6rem, -50%) rotate(270deg);
//   text-align: center;
//   width: 30%;
// }

&__headerTextLabel{
  @include lib.bodyMetaMini(false, rgba(lib.color('body'), 0.5));
  display: block;
  margin-bottom: 1rem;
  @include lib.mq($from: 'laptop') {
    margin-bottom: 1.5rem;
  }
  @include lib.mq($from: 'monitor') {
    display: none;
  }
}


&__area{
    z-index: 1;
    display: flex;
    position: relative;
    align-items: center;
    color: lib.color('body');
}

&__headerHamburger{
    max-width: 1110px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

&__head{
    @include lib.heading8();    
    color: lib.color('secondaryTint1');
    transition: all 500ms;
    // position: relative;
    border-bottom: 1px solid #eeebe833;
    padding: 0 0 1rem 0;

    @include lib.mq($from: 'laptop') {
      flex: 0 0 66%;
      padding: 0 60px 0 0;
      border-bottom: none;
      border-right: 1px solid #eeebe833;
    }

    @include lib.mq($from: 'desktop') {
      max-width: 630px;
    }

    @include lib.mq($from: "laptop") {
      font-size: lib.px2rem(58px);
    }

    // &::before{
    //   @include lib.mq($until: 'monitor') {
    //     @include lib.bodyMetaMini(false, rgba(lib.color('body'), 0.5));
    //     content: attr(data-label);
    //     position: absolute;
    //     top: -2rem;
    //     left: 0;                
    //   }
    // }
}

&__para{
    padding: 1rem 0 0 0;
    @include lib.mq($from: 'laptop') {
        padding: 0 0 0 60px
    }
}

&__paraText{
  @include lib.bodyText();
  color: lib.color('secondaryTint1');
  transition: all 500ms;
  margin-bottom: 1rem;
}

&__link{
  @include lib.bodyMetaMini(false, lib.color('primary'));
  padding: 1rem 0;

  &::after{
    content: "→";
    margin-left: 0.25rem;
  }
}

&__actions{
    margin-top: 1.875rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: inline-flex;    
}

}